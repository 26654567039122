import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { Button, Row, Col } from 'react-bootstrap'

class NewsletterForm extends React.Component {
  state = {
    FNAME: null,
    LNAME: null,
    email: null,
  }

  handleChange = (event) => {
    console.log({
      [`${event.target.name}`]: event.target.value,
    });
    this.setState({
      [`${event.target.name}`]: event.target.value,
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    console.log('submit:', this.state);

    addToMailchimp(this.state.email, this.state)
      .then(({ msg, result }) => {
        console.log('msg', `${result}: ${msg}`);

        if (result !== 'success') {
          throw msg;
        }
        let successMessage = document.querySelector('.form-container');
        successMessage.innerHTML = '<p>Thank you for subscribing!</p>';
        //alert(msg);
      })
      .catch((err) => {
        console.log('err', err);
        alert(err);
      });
  }

  render() {
    return (
<Row>
        <Col>
        <div className="form-container">
        <p>Stay up-to-date with Teton Valley Aquatics by signing up for our newsletter!</p>
        {/* <form name="pShoppingCartFormBean" method="POST" action="https://tetonvalleyaquatics.app.neoncrm.com/np/clients/tetonvalleyaquatics/submitSubscription.jsp">
   
   <input type="hidden" name="subscription" value="3" />
   <input type="hidden" name="skipDuplicateRequestCheck" value="1" />
   <table cellSpacing="2" cellPadding="2" border="0" width="99%" >
      <tr><td>Name:</td><td><input type="text" name="subscriber.name" maxlength="50" size="25" /></td></tr>
      <tr><td>Email:</td><td><input type="text" name="subscriber.email1" maxlength="80" size="25" /></td></tr>
          <tr><td colspan="2" align="center">
         <div style={{display:'none', position: 'fixed', border: '2px solid #ccc', backgroundColor: '#EEE', padding: '2px 2px 2px 3px', borderRadius: '5px', textAlign: 'left', zIndex: '99999'}} id="subscriptionCaptcha"><div style={{fontSize: '12px', marginLeft: '3px', marginBottom: '2px', display: 'inline-block'}}>Confirm that you are not a bot.</div><div style={{float: 'right', marginRight: '2px', color: '#ccc', cursor: 'pointer'}} onclick="neonSubscriptionCloseCaptcha();" onmouseover="this.style.color='#aaa'" onmouseout="this.style.color='#ccc'">&times;</div>
            

<div className="g-recaptcha" data-sitekey="6Lcsbw8UAAAAAJ6H5uLehos0lMXkHl3qiSGDVfTJ"></div>
         </div>
         <input id="subscriptionSubmitButton" name="submitButton" onclick="return neonSubscriptionSubmit();" type="submit" value="Subscribe" className="button primary" />
      </td></tr>
   </table>
</form> */}

{/* <iframe src="https://teton-valley-aquatics.s3.us-west-2.amazonaws.com/neon-crm-newsletter/newsletter.html" width="100%" height="500" frameBorder="0" scrolling="no" allowTransparency="true" allow="encrypted-media" title="Newsletter Form"></iframe>; */}
    <form onSubmit={this.handleSubmit}>

    <div className="form-row">
      <div className="mb-2 col-sm-6">
        <input
        onChange={this.handleChange}
        autoComplete="fname"
        name="FNAME"
        id="FNAME"
          type="text"
          className="form-control"
          placeholder="First Name"
        />
      </div>
      <div className="mb-2 col-sm-6">
        <input
        onChange={this.handleChange}
        name="LNAME"
        id="LNAME"
          type="text"
          className="form-control"
          placeholder="Last Name"
        />
      </div>
      </div>
      <div className="form-row">
      <div className="mb-2 col-sm-12">
        <input
        name="email"
        id="email"
        type="email"
        onChange={this.handleChange}
        className="form-control"
        placeholder="Email Address"
        />
      </div>
      </div>
      <div className="form-row mt-4">
      <div className="col-sm-12">
        <Button className="btn-block btn btn-action" type="submit" value="Subscribe">
          Submit
        </Button>
      </div>
      </div>
  </form>
  </div>
  </Col>
  </Row>
    )
  }
}

export default NewsletterForm