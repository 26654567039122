import React from 'react';
import { Button } from 'react-bootstrap'
import NewsletterModal from "./NewsletterModal"

function Modal(props) {
    const [modalShow, setModalShow] = React.useState(false)
  
    return (
      <>
         <Button className={props.style}
            onClick={() => setModalShow(true)}
         >{props.signUpText}</Button>

         <NewsletterModal show={modalShow} onHide={() => setModalShow(false)} />
      </>
    )
  }

  export default Modal;