import React from 'react'
import { Modal } from 'react-bootstrap'
import styles from "./NewsletterModal.module.css"
import NewsletterForm from './NewsletterForm'

function NewsletterModal(props) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="cotnained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className={styles.modalTitle} id="newsletter-modal">
                    Sign Up For Our Newsletter
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <NewsletterForm />
            </Modal.Body>
        </Modal>
    )
}

export default NewsletterModal;