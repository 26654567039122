import React from "react"
import "./footer.css"
import { Link } from "gatsby"
import { Container, Row, Col, Button } from "react-bootstrap"
import FooterLogo from "./FooterLogo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faInstagramSquare,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons"

import Modal from '../NewsletterModal/ModalButton'
import styles from "../HomeNewsletterBlock/HomeNewsletterBlock.module.css"
import GivingTuesdayModalButton from "../GivingTuesdayModal/GivingTuesdayModalButton"

const Footer = () => {
  return (
    <>
      <footer className="mt-auto">
        <Container style={{
            paddingTop: '2%',
            paddingBottom: '2%',
        }}>
          <Row>
            <Col sm={3} className="text-center text-lg-left mb-2">
            <Link to="/">
              <FooterLogo />
              </Link>
            </Col>
            <Col sm={3} className="footerTxt text-center text-lg-left mb-2">
            <p style={{
              fontSize: 'larger'
            }}><strong>Contact</strong></p>
              <p>Stacy Stamm<br /><em>Executive Director</em></p>
                <p>
                  <a href="tel:208-607-8975" style={{color: '#95d8f3'}}>208-607-8975</a>
                  {/* <br /><small>M-Th 11am&mdash;2pm</small> */}
                  </p>
                <p>PO Box 583<br />
                Driggs, ID 83422</p>
            </Col>
            <Col sm={3} className="footerTxt text-center text-lg-left mb-2">
            <p style={{
              fontSize: 'larger'
            }}><strong>Menu</strong></p>
              <p><Link to="/about" className="footerLink">About</Link></p>
              <p><Link to="/programs-events" className="footerLink">Programs &amp; Events</Link></p>
              <p><Link to="/the-project" className="footerLink">The Project</Link></p>
              <p><Link to="/connect" className="footerLink">Connect</Link></p>
            </Col>
            <Col sm={3} className="footerTxt text-center text-lg-left">
              <p style={{
                fontSize: 'larger'
              }}><strong>Follow Us</strong>
              <a
              href="https://www.facebook.com/tetonvalleyaquatics"
              target="_blank"
              rel="noopener noreferrer"
              role="icon"
              aria-label="Teton Valley Trails &amp; Pathways on Facebook"
              title="Teton Valley Trails &amp; Pathways on Facebook"
            >
              <FontAwesomeIcon
                icon={faFacebookSquare}
                className="footerSocialIcon"
                aria-hidden="true"
              />
            </a>

            <a
              href="https://www.instagram.com/tetonvalleyaquatics"
              target="_blank"
              rel="noopener noreferrer"
              role="icon"
              aria-label="Teton Valley Aquatics on Instagram"
              title="Teton Valley Aquatics on Instagram"
            >
              <FontAwesomeIcon
                icon={faInstagramSquare}
                className="footerSocialIcon"
                aria-hidden="true"
              />
            </a>
            </p>
            <Row className="my-4">
              <Col className="text-center text-lg-left">
                <Modal className="newsletterBtn"
                signUpText="Stay In The Loop" 
                style={styles.footerNewsletterButton}/>
              {/* <Button className="newsletterBtn">Stay In The Loop</Button> */}
              </Col>
            </Row>
            <Row>
              <Col className="text-center text-lg-left">
              {/* <GivingTuesdayModalButton
                      signUpText="Donate!"
                      className="donateBtn"
                    /> */}
                <a href="https://tetonvalleyaquatics.app.neoncrm.com/forms/general-donations" target="_blank" rel="noopener noreferrer" className="donateBtn">Donate!</a>
              {/* <Link to="/donate"><Button className="donateBtn" variant="">Donate</Button></Link>   */}
              </Col>
            </Row>
                        
            </Col>
          </Row>         
        </Container>
fomr
        <Container fluid style={{
            borderTop: '1px solid white'
        }}>
            <Row style={{
                margin: 0
            }}>
                <Col className="text-center">
                <div>
          
                <p
                  className="footer-text pt-2 text-center"
                  style={{ fontSize: "smaller", color: "white" }}
                >
                  © {new Date().getFullYear()}, Teton Valley Aquatics | Site by LGVcreative &amp; <a href="https://inlightmedia.com" className="footerLink">Inlight Media</a>  {/* <Link to="/privacy-policy" className="footerLink">Privacy Policy</Link> */}
                  </p>
                  </div>
                </Col>
            </Row>
        </Container>
      </footer>
    </>
  )
}

export default Footer
