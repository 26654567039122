import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'gatsby'
import './SlideMenu.css'
import { Button } from "react-bootstrap"
import styles from "./nav.module.css"

class SlideMenu extends React.Component {
    showSettings (event) {
        event.preventDefault();
    }
    render () {
        return (
            <Menu right>
                <Link to="/about" className="menu-item">About</Link>
                <Link to="/programs-events" className="menu-item">Programs &amp; Events</Link>
                <Link to="/the-project" className="menu-item">The Project</Link>
                <Link to="/connect" className="menu-item">Connect</Link>
                {/* <Link
                    className="navbar-link"
                    to="/donate/"
                    activeClassName="active"
                    style={{
                        marginTop: '20px',
                    }}
                  >
                    <Button className={styles.donateBtn}>Donate</Button>
                  </Link> */}
                  <div className='my-4'><a href="https://tetonvalleyaquatics.app.neoncrm.com/forms/general-donations" target="_blank" rel="noopener noreferrer"><Button className={styles.donateBtn}>Donate</Button></a></div >
            </Menu>
        )
    }
}

export default SlideMenu;