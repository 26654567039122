import React, { useState } from "react"
import CircleLoader from "react-spinners/CircleLoader"

const GivingTuesdayIFrame = () => {
  const [loading, setLoading] = useState(true)
  const hideLoading = () => {
    setLoading(false)
  }
  return (
    <>
      <div style={{
          width: '100%',
          display: 'flex',
          height: '800px',
          alignItems: 'center',
          justifyContent: 'center',
      }}>
        {loading ? (
          <div style={{
            position: 'absolute'
          }}>
            <CircleLoader 
                color={'white'}
                size={200}
            />
          </div>
        ) : null}
            <div style={{
                width: '100%'
            }}>
        <iframe
          src="https://www.flipcause.com/widget/MTAwNjI4"
          width="100%"
          height="800"
          frameBorder="no"
          onLoad={hideLoading}
        ></iframe>
        </div>
      </div>
    </>
  )
}

export default GivingTuesdayIFrame
