import React from "react"
import { Link } from "gatsby"
import { Container, Navbar, Nav, Button } from "react-bootstrap"
import styles from "./nav.module.css"
import HeaderLogo from "./HeaderLogo"
import SocialBar from "./SocialBar"
import SlideMenu from "./SlideMenu"
import GivingTuesdayModalButton from "../../components/GivingTuesdayModal/GivingTuesdayModalButton"
import AnnouncementBar from "./AnnouncementBar"

const Navigation = () => {
  return (
    <>
    <AnnouncementBar />
      <SocialBar />
      <div className="container-fluid" style={{
          position: 'relative',
          backgroundColor: '#ffffffda',
      }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            position: "absolute",
          }}
        >
            <div className='leftBlueBar'
            style={{
                backgroundColor: '#1A7DDB',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                

            }}
            > </div>
            <div className="triangle-top-left" style={{
              flexGrow: 1,
              postion: 'relative'
            }}>

            </div>
        
        
        <div className="transparentNavSpan" >
          </div>
        
          {/* <div id="blue-bar"
            style={{
              backgroundColor: "#1A7DDB",
              width: "10%",
              height: "120px"
            }}
          >
          </div> */}
          
          {/* <div>
            <SlashBackground
              style={{
                height: "110px",
                width: "96px",
              }}
            />
          </div> */}
          
        </div>
        
        <Container >
          <Navbar
            expand="lg"
            id="site-navbar"
            variant="light"
            
            //className={styles.navbar}
            className="navbar-fixed-top mr-sm-2"
            style={{
              height: '120px'
            }}
          >
            
            
            <Navbar.Brand>
              <div className="logo-container" style={{
                marginTop: '-17px',
                
              }}>
                <div
                >
                  <Link to="/">
                    <HeaderLogo />
                  </Link>

                  
                </div>
                </div>
              </Navbar.Brand>
              {/* <div className="triangle-top-left"></div> */}
              <div className="slideMenuToggle">
                <SlideMenu />
              </div>


                <Nav className="justify-content-end" style={{
                    flexGrow: "1",          
            }}>
                  <Link
                    className="navbar-link"
                    to="/about/"
                    activeClassName="active"
                  >
                    <Nav.Link as="span">
                      About
                    </Nav.Link>
                  </Link>
                  <Link
                    className="navbar-link"
                    to="/programs-events/"
                    activeClassName="active"
                  >
                    <Nav.Link as="span">Programs &amp; Events</Nav.Link>
                  </Link>
                  <Link
                    className="navbar-link"
                    to="/the-project/"
                    activeClassName="active"
                  >
                    <Nav.Link as="span">The Project</Nav.Link>
                  </Link>
                  <Link
                    className="navbar-link"
                    to="/connect/"
                    activeClassName="active"
                  >
                    <Nav.Link as="span">Connect</Nav.Link>
                  </Link>
                  {/* <GivingTuesdayModalButton
                      signUpText="Donate!"
                      className="donateBtn"
                    /> */}
                  {/* <Link
                    className="navbar-link"
                    to="/donate/"
                    activeClassName="active"
                  >
                    <Button className={styles.donateBtn} variant="">Donate</Button>
                  </Link> */}
                  <a href="https://tetonvalleyaquatics.app.neoncrm.com/forms/general-donations" target="_blank" rel="noopener noreferrer" >
                  <Button className={styles.donateBtn} variant="">Donate</Button>
                  </a>
                </Nav>
            </Navbar>
            </Container>

      </div>
      
    </>
  )
}

export default Navigation
