import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faExternalLink} from "@fortawesome/pro-solid-svg-icons"

 // Render \n to <br />
 const Text = ({ children }) => (
    <p style={{ whiteSpace: 'pre-line' }}>{children}</p>
  )

  const options = {
    renderNode: {
      
      // Render \n to <br />
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,

      [BLOCKS.EMBEDDED_ASSET]: ({
        data: {
          target: { fields },
        },
      }) => (
        <div
          dangerouslySetInnerHTML={{
            __html: `<img src="${fields.file["en-US"].url}" alt="${fields.title["en-US"]}" class="fluid" style="margin: 20px 0 20px 0; max-width: 100%" />`,
          }}
        />
      ),
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        // If you are using contentful.js client library, the referenced entry is resolved
        // automatically and is available at `node.data.target`.
        //const referencedEntry = getEntryWithId(node.data.target.sys.id);
        //const referencedEntry = node.data.target.sys.id;
        //console.log(node.data.target.fields.slug)
        if (node.data.target.sys.contentType.sys.id === 'blogPost') {
        return <Link to={`/news/${node.data.target.fields.slug["en-US"]}`} style={{color: '#D1DBFD'}}>{children}</Link>
        } else {
            return <Link to={`/${node.data.target.fields.slug["en-US"]}`} style={{color: '#D1DBFD'}}>{children}</Link>
        }
      },
      [INLINES.HYPERLINK]: ({ data }, children) => {
        console.log(data)        
        return (
         <>
              <a href={data.uri} className="underline" target="_blank" rel="noopener noreferrer">
              {children}{' '} <FontAwesomeIcon
                icon={faExternalLink}
                aria-hidden="true"
                size="xs"
          />
            </a>
         </>
        )
      },

      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const fields = node.data.target.fields.imageLink
        const imgLnk = node.data.target.fields.url
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: `<a href="${imgLnk["en-US"]}"><img src="${fields["en-US"].fields.file["en-US"].url}" alt="${fields.title}"  /></a>`,
            }}
          />
        )
      },
    },
  }

  function getEntryWithId(entryId) {
      console.log(entryId)
    const mockEntry = {
      fields: {
        slug: 'slug',
      },
    };
    return mockEntry;
  }

const AnnouncementBar = (props) => {
    const data = useStaticQuery(graphql`
    {
      contentfulAnnouncementBar {
        childContentfulAnnouncementBarAnnouncementTextRichTextNode {
          json
        }
        active
      }
    }
  `)

  const announcementBar = data.contentfulAnnouncementBar;
  console.log(announcementBar)
  return (
    <>
        {announcementBar.active && 
            (<div
          className="container-fluid"
          style={{
            backgroundColor: "#003366",
          }}
        >
          <Container>
            <Row>
              <Col className="text-center">
                <p style={{marginBottom: '0.5rem', marginTop: '0.5rem', color: 'white'}}>
                {documentToReactComponents(
                    announcementBar.childContentfulAnnouncementBarAnnouncementTextRichTextNode.json,
                    options
              )}
                </p>
              </Col>
            </Row>
          </Container>
        </div>)
        }
    </>
  )
}

export default AnnouncementBar
