import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faInstagramSquare,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons"
import styles from "./nav.module.css"

const SocialBar = () => {
  return (
    <div
      className="container-fluid"
      style={{
        backgroundColor: "#1A7DDB",
      }}
    >
      <Container>
        <Row>
          <Col className="text-right">
            <a
              href="https://www.facebook.com/tetonvalleyaquatics"
              target="_blank"
              rel="noopener noreferrer"
              role="icon"
              aria-label="Teton Valley Trails &amp; Pathways on Facebook"
              title="Teton Valley Trails &amp; Pathways on Facebook"
            >
              <FontAwesomeIcon
                icon={faFacebookSquare}
                className={styles.socialIcon}
                aria-hidden="true"
              />
            </a>

            <a
              href="https://www.instagram.com/tetonvalleyaquatics"
              target="_blank"
              rel="noopener noreferrer"
              role="icon"
              aria-label="Teton Valley Aquatics on Instagram"
              title="Teton Valley Aquatics on Instagram"
            >
              <FontAwesomeIcon
                icon={faInstagramSquare}
                className={styles.socialIcon}
                aria-hidden="true"
              />
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SocialBar
