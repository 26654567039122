import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import styles from "./GivingTuesdayModal.module.css"
import GivingTuesdayIFrame from "./GivingTuesdayIFrame"

function GivingTuesdayModal(props) {
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Support Teton Valley Aquatics
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <GivingTuesdayIFrame />
            </Modal.Body>
        </Modal>
    )
}

export default GivingTuesdayModal;